<template>
  <div :class="['lp-header', { 'lp__header--open': isOpen }]" id="lp-header">
    <SystemAlert v-if="isSystemAlertEnabled" />
    <div class="lp__nav--overlay" v-if="isOpen && isMobile"></div>
    <b-navbar
      id="landing-header"
      toggleable="md"
      :sticky="true"
      fixed="top"
      :class="{
        'lp-nav__open': isOpen,
        'header-sticky': isHeaderSticky,
        'header-background': headerBackground,
        'lp-nav__system-alert': isSystemAlertEnabled,
        'default-header': defaultHeader
      }"
    >
      <div class="container p-0">
        <div class="lp__nav--left">
          <b-navbar-brand href="/" v-if="isOpen">
            <img
              :src="
                require(`@/assets/images/logo/whoa-logo-black-${logoLanguage}.svg`)
              "
              height="48"
              alt="logo"
            />
          </b-navbar-brand>
          <b-navbar-brand href="/" v-else>
            <img :src="logoSrc" height="48" alt="logo" />
          </b-navbar-brand>
          <environment-label />
          <b-navbar-brand href="/discovery" v-if="!isMobile">
            {{ $t("general.menu.discovery") }}
          </b-navbar-brand>
          <b-navbar-brand href="/coursewares" v-if="!isMobile">
            {{ $t("course.breadcrumb.course") }}
          </b-navbar-brand>
          <template v-if="isHeaderSticky && isTab">
            <HeaderSearch
              :isSearchFocused="isSearchFocused"
              :isHeaderSticky="isHeaderSticky"
              @setFocus="handleSearchFocus"
              ref="searchRef"
              v-if="isSearchFocused"
            />
            <div class="search-btn" @click="setSearchActive" v-else>
              <img :src="searchTabIcon" alt="search" />
            </div>
          </template>
          <HeaderSearch
            :isSearchFocused="isSearchFocused"
            @setFocus="handleSearchFocus"
            ref="searchRef"
            v-if="desktopHeaderSearch"
          />
        </div>
        <b-navbar-toggle
          target="navbar-toggle-collapse"
          @click="onToggle"
          v-if="isMobile"
        >
          <template #default="{ expanded }">
            <span :class="{ 'menu-close': isOpen }" v-if="expanded">
              <img src="@/assets/logo/menu-close.svg" />
            </span>
            <span v-else>
              <img :src="mobileMenuImg" />
            </span>
          </template>
        </b-navbar-toggle>

        <b-collapse id="navbar-toggle-collapse" is-nav>
          <div class="lp__user--menu">
            <HeaderSearch
              :isSearchFocused="isSearchFocused"
              @setFocus="handleSearchFocus"
              ref="searchRef"
              class="mobile-search"
              v-if="isMobile"
            />
            <div class="mobile__menu--links" v-if="isMobile">
              <b-navbar-brand href="/discovery">
                {{ $t("general.menu.discovery") }}
              </b-navbar-brand>
              <b-navbar-brand href="/coursewares">
                {{ $t("course.breadcrumb.course") }}
              </b-navbar-brand>
            </div>
            <div class="login-links">
              <LxpButton
                :variant="isMobile ? 'outline-primary' : 'link'"
                type="submit"
                name="login"
                className="text-capitalize login-link"
                @click="login(getRedirectURI)"
              >
                {{ $t("landing_page.header.button.login") }}
              </LxpButton>
              <LxpButton
                :variant="lxpButtonVariant"
                type="submit"
                name="register"
                className="text-capitalize register-link"
                @click="register(getRedirectURI)"
              >
                {{ $t("general.register") }}
              </LxpButton>
            </div>
            <LxpLanguageSelect
              :selected="selectedLanguage"
              :allLanguage="getAllLanguage()"
              :language="language"
              :parentClass="parentClass()"
              @set-language="setSelectedLanguage"
            />
          </div>
        </b-collapse>
      </div>
    </b-navbar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import authenticationMixin from "@/core/mixins/authentication";
import userLanguage from "@/core/mixins/userLanguage";
import getLogo from "@/core/mixins/getLogo";
import SystemAlert from "@/views/SystemAlert.vue";
import { LxpLanguageSelect, LxpButton } from "didactica";
import userLanguageMixin from "@/core/mixins/userLanguage";
import HeaderSearch from "./HeaderSearch.vue";
import EnvironmentLabel from "@/components/Shared/EnvironmentLabel.vue";

export default {
  mixins: [authenticationMixin, userLanguage, getLogo, userLanguageMixin],
  components: {
    SystemAlert,
    LxpLanguageSelect,
    LxpButton,
    HeaderSearch,
    EnvironmentLabel
  },
  data() {
    return {
      isOpen: false,
      scrollPosition: null,
      selectedLanguage: {},
      mobileMenuWhite: require("@/assets/logo/menu-white.svg"),
      mobileMenuBlack: require("@/assets/logo/menu.svg"),
      searchTabIcon: require("@/assets/images/search/search-primary.svg"),
      isDesktop: false,
      isTab: false,
      isMobile: false,
      isSearchFocused: false
    };
  },
  computed: {
    ...mapGetters(["allConfig", "isSystemAlertEnabled", "getPageNotFound"]),
    headerBackground() {
      return (
        !this.$keycloak.authenticated && this.$route.name === "Courses Detail"
      );
    },
    isHeaderSticky() {
      let fixedHeader = !this.isOpen && this.scrollPosition > 20;
      this.$store.commit("SET_HEADER_FIXED", fixedHeader);
      return fixedHeader;
    },
    isAuthenticated() {
      return this.$keycloak.authenticated;
    },
    defaultHeader() {
      return (
        this.$route.name === "Courses" ||
        this.$route.name === "TermsAndConditions" ||
        this.$route.name === "Unauthorized" ||
        this.$route.name === "awards-share" ||
        this.$route.name === "Achievements Detail" ||
        (this.getPageNotFound &&
          this.$route.name === "Courses Detail" &&
          !this.isAuthenticated)
      );
    },
    logoSrc() {
      return this.isHeaderSticky || this.defaultHeader
        ? require(`@/assets/images/logo/whoa-logo-black-${this.logoLanguage}.svg`)
        : require(`@/assets/images/logo/whoa-logo-white-${this.logoLanguage}.svg`);
    },
    desktopHeaderSearch() {
      return (this.isHeaderSticky || this.defaultHeader) && this.isDesktop;
    },
    mobileMenuImg() {
      return this.isHeaderSticky || this.defaultHeader
        ? this.mobileMenuBlack
        : this.mobileMenuWhite;
    },
    lxpButtonVariant() {
      return this.isMobile || this.isHeaderSticky || this.defaultHeader
        ? "accent"
        : "primary";
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
    window.addEventListener("scroll", this.updateScroll);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.updateScroll);
  },
  methods: {
    onToggle() {
      this.$refs.searchRef.resetSearchResult();
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        document.body.style.overflow = "hidden";
        if (document.getElementById("cd-footer-section")) {
          document.getElementById("cd-footer-section").style.zIndex = "-1";
        }
      } else {
        document.body.style.overflow = "auto";
        if (document.getElementById("cd-footer-section")) {
          document.getElementById("cd-footer-section").style.zIndex = "9999";
        }
      }
      this.$emit("navToggle", this.isOpen);
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    onResize() {
      this.isDesktop = window.innerWidth > 991;
      this.isTab = window.innerWidth > 767 && window.innerWidth < 992;
      this.isMobile = window.innerWidth < 768;
    },
    handleSearchFocus(value) {
      this.isSearchFocused = value;
    },
    setSearchActive() {
      this.isSearchFocused = true;
      setTimeout(() => {
        this.$refs.searchRef.$refs.searchQuery.focus();
      }, 0);
    }
  }
};
</script>
<style lang="scss" scoped>
.lp-header {
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  .lp__nav--overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(45, 45, 45, 0.25);
    backdrop-filter: blur(4px);
    z-index: 4;
    pointer-events: none;
    overflow: hidden;
  }
}
@mixin setPositionTop($bannerHeight) {
  &.lp-nav__system-alert {
    top: $bannerHeight;
  }
}
.navbar {
  padding: 8px 16px;
  font-family: $font-family;
  .lp__nav--left {
    display: flex;
    align-items: center;
    flex: 1;
    .navbar-brand {
      margin: 0;
      padding: 0;
      @include button-label;
      color: $brand-neutral-0;
      &:not(:first-child) {
        margin-left: 48px;
      }
    }
    .search-btn {
      margin-left: auto;
      margin-right: 16px;
    }
  }

  .navbar-collapse {
    justify-content: flex-end;
    flex-grow: unset;
  }

  .navbar-toggler {
    padding: 0;
    box-shadow: none;
    border: none;
    .menu-close {
      width: 40px;
      min-width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $brand-primary-75;
      @include flex-horizontal-center;
    }
  }

  .lp__user--menu {
    @include flex-horizontal-end;
    .login-links {
      @include flex-center;
    }
    .btn {
      height: 36px;
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
    .register-link {
      padding: 8px 23px;
      &:hover {
        box-shadow: none;
      }
    }
    .login-link {
      padding: 8px 14px;
      color: $brand-neutral-0;
      &:hover {
        color: $brand-neutral-0;
        background-color: transparent;
      }
    }
    .mobile__menu--links {
      @include flex-column-start;
      margin-left: -16px;
      width: calc(100% + 32px);
      overflow: hidden;
      .navbar-brand {
        width: 100%;
        margin: 0;
        padding: 17px 16px;
        @include button-label;
        color: $brand-primary;
        text-align: left;
        border-top: 1px solid $brand-neutral-200;
        &:last-child {
          border-bottom: 1px solid $brand-neutral-200;
        }
      }
    }

    .lp-nav__lang {
      @include flex-horizontal-center;
      padding: 8px 12px;

      &:hover {
        background: linear-gradient(
            0deg,
            rgba(0, 87, 224, 0.08),
            rgba(0, 87, 224, 0.08)
          ),
          #fbfdfd;
        border-radius: 4px;
      }

      img {
        margin-right: 0.5rem;
      }

      p {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $brand-secondary-text;
      }
    }
  }

  &.lp-nav__open {
    background: $brand-neutral-0;
    box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
    padding-bottom: 0;

    .collapse.show {
      .lp__user--menu {
        @include flex-column-start;

        .btn {
          margin-bottom: 25px;

          &.btn-link {
            justify-content: center !important;
          }
        }

        .lp-nav__lang {
          border-top: 1px solid #cbcbcb;
          padding-top: 25px;
          width: 100%;
        }
      }
    }
  }
  &.header-sticky,
  &.default-header {
    background: $brand-primary-50;
    .lp__nav--left {
      .navbar-brand {
        color: $brand-primary;
      }
    }
    .lp__user--menu {
      .login-link {
        color: $brand-primary;
        &:hover {
          background-color: $brand-primary-100;
          color: $brand-primary-400;
        }
      }
    }
  }
}

// arabic alignment
[dir="rtl"] {
  .navbar {
    .navbar-collapse {
      .lp-nav__lang {
        margin: 0 13px 0 0;

        img {
          margin-left: 0.5rem !important;
        }
      }
    }

    .lp__user--menu {
      button + button {
        margin-right: 12px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .navbar {
    &.header-sticky {
      &.default-header {
        box-shadow: 0px 4px 8px 4px rgba(33, 33, 33, 0.2);
      }
    }
  }
}
@media screen and (min-width: 1280px) {
  .lp-header .container {
    max-width: 1248px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar {
    .lp__nav--left {
      .navbar-brand {
        &:first-child {
          margin-right: 0;
        }
        &:not(:first-child) {
          margin-left: 18px;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .navbar {
    .lp__nav--left {
      position: relative;
      .navbar-brand {
        &:not(:first-child) {
          margin-left: 24px;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .navbar {
    &.lp-nav__open {
      position: fixed;
    }
  }
  .login-links {
    width: 100%;
    padding: 16px 0px;
    .btn {
      width: 49%;
      height: 54px !important;
      margin: 0 !important;
      &:not(:first-child) {
        margin-left: 2% !important;
      }
    }
    .btn-outline-primary {
      color: $brand-primary !important;
      border: 1px solid $brand-primary !important;
    }
  }
}
</style>
<style lang="scss">
.lp-header {
  .lxp-select {
    .user-fluid-layout__language {
      .lxp-dropdown__menu-text {
        padding: 0px;
        span {
          @include button-label;
          text-transform: uppercase;
          color: $brand-neutral-0;
        }
      }
      .ufl-language__div {
        position: absolute;
      }
    }
  }
}
.header-sticky,
.default-header {
  .lxp-select {
    .user-fluid-layout__language {
      &:not(.show) {
        .lxp-dropdown__menu-text {
          span {
            color: $brand-primary;
          }
        }
      }
      .lxp-dropdown__menu-text {
        &:hover {
          span {
            color: $brand-primary-400;
          }
        }
      }
    }
  }
}
[dir="rtl"] {
  .user-fluid-layout__language {
    .lxp-dropdown__menu-text {
      padding: 8px !important;
      span {
        color: $brand-primary-400 !important;
      }
    }
    .ufl-language__div {
      .ufl-language__list {
        .ufl-language__item {
          &.ufl-language__selected-item {
            &::after {
              right: auto !important;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .lp__user--menu {
    .lxp-select {
      margin-left: -16px;
      width: calc(100% + 32px);
      border-top: 1px solid $brand-neutral-200;
      .user-fluid-layout__language {
        margin: 0;
        &.show {
          .lxp-dropdown__menu-text {
            background: transparent;
          }
        }
        .lxp-dropdown__menu-text {
          padding: 22px 16px;
          text-align: left;
          &:hover {
            background: transparent;
          }
          span {
            color: $brand-neutral-700;
          }
        }
        .ufl-language__div {
          left: 0;
          right: 0;
          top: auto;
          bottom: 0;
          position: fixed;
          height: calc(100% - 415px);
          .ufl-language__list {
            border-radius: 0;
            background: $brand-primary-50;
            box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25) inset;
            margin: 0;
            width: 100%;
            max-height: 100%;
            padding: 20px 4px 20px 16px;
            > div {
              &:not(:first-child) {
                margin-top: 18px;
              }
            }
            label {
              padding: 0;
              margin-bottom: 6px;
              @include body-regular($brand-neutral-900, 500);
            }
            .ufl-language__item {
              p {
                @include body-regular($brand-neutral-700);
              }
              span {
                @include body-medium;
                color: $brand-neutral-300;
              }
            }
          }
        }
      }
    }
  }
}
</style>
